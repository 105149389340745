<template>
  <div contact-data-vuetify>
    <v-app class="rounded transparent">
      <v-main>
        <ContactUs
          :canada="canada || false"
          :service="service || ''"
          :services="services || ''"
          :serviceicons="serviceicons || true"
          :light="light || false"
          :assigntolocation="assigntolocation || 0"
          :assigntodistrict="assigntodistrict || 0"
          :bulkitempickup="bulkitempickup || ''"
          :stopservice="stopservice || false"
          :phone="phone || '844-708-7274'"
          :lang="lang || 'en'"
          :terms="terms||(canada&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/nos-services/conditions/':(canada&&lang==='en')||(canada&&!lang)?'https://www.wasteconnectionscanada.com/terms-of-use/':'https://www.wasteconnections.com/terms-of-use/')"
          :privacy="privacy||(canada&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/nos-services/confidentialite/':(canada&&lang==='en')||(canada&&!lang)?'https://www.wasteconnectionscanada.com/privacy-policy/':'https://www.wasteconnections.com/privacy-policy/')"
          :map="map||maplink||(canada&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/emplacements/':(canada&&lang==='en')||(canada&&!lang)?'https://www.wasteconnectionscanada.com/locations/':'https://www.wasteconnections.com/locations/')"
          :pay="pay||(canada&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/paiement-de-factures-en-ligne/':(canada&&lang==='en')||(canada&&!lang)?'https://www.wasteconnectionscanada.com/bill-pay/#7000':'https://www.wasteconnections.com/bill-pay/#1010')"
          :name="name||'Waste Connections'" />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import ContactUs from './components/ContactUs';

export default {
  name: 'App',
  props: {
    canada: Boolean,
    service: String,
    services: String,
    serviceicons: Boolean,
    light: Boolean,
    assigntolocation: Number,
    assigntodistrict: Number,
    bulkitempickup: String,
    stopservice: Boolean,
    phone: String,
    lang: String,
    terms: String,
    privacy: String,
    map: String,
    maplink: String,
    pay: String,
    name: String,
  },
  components: {
    ContactUs,
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');
@import url('https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css');
[contact-data-vuetify]{overflow-y:hidden!important}
#app{font-family: Roboto, Helvetica, Arial, sans-serif;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;text-align: center;}
.grecaptcha-badge{visibility:hidden}
.pac-item{font-family:Roboto,sans-serif !important;font-weight:400 !important;color:rgba(0,0,0,.87) !important;line-height:40px !important;font-size:16px;cursor:pointer}
.pac-item-query{font-family:Roboto,sans-serif !important;font-size:16px}
.pac-item:hover{background-color:rgb(246,246,246) !important;transition: .3s cubic-bezier(.25,.8,.5,1)}
.pac-container{color:rgba(0,0,0,.87)!important;max-height:166px;overflow-y:auto}
.pac-icon,.pac-icon-marker{width:0 !important;height:0 !important;background:none !important;background-image:none !important}
.transparent{background-color:transparent !important;border-color:transparent !important}
.row>*{margin-left:initial!important}
div>.rounded{border-radius:4px!important}
div.container--fluid{padding:0px 0px 24px 0px!important}
div.v-application--wrap{min-height:100%!important}
.v-item-group.v-bottom-navigation .v-btn{background-color:transparent!important;height:inherit!important;min-width:80px!important}
.v-text-field__slot>.v-label{pointer-events:none}
</style>
